.document-view {
    .custom-section-table{
        .ant-table-body {
            max-height: calc(100vh - 382px) !important;
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}