.activity-entries-management {
    .club-section-table{
        .ant-table-body {
            height: calc(100vh - 364px);
            max-height: 100% !important;
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }

    .state-section-table{
        .ant-table-body {
            height: calc(100vh - 364px);
            max-height: 100% !important;
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }

}