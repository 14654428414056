.ant-breadcrumb { 
    .ant-breadcrumb-link {
        color: var(--color-dark-blue);
    }
}

.create-day-log {
    .ant-breadcrumb {
        margin-top: 0px !important;
    }

    .ant-form-item {
        margin-bottom: 0px;
    }
}