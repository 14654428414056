.document-card-view {
    .document-source-select {
        padding-left: 32px;
        min-width: 75%;
    }
}

.document-item-card-view {
    .custom-logo {
        min-height: 48px;
        width: auto;
        border-radius: 0;
    }

    box-shadow: none;
    border-radius: 0px;
    border-bottom: 2px solid #f0f0f0;
}
