.full-height{
    height: 100%;
}

.full-width{
    width: 100%;
}

$sizes: (5, 10, 15, 16, 20, 25, 30, 32, 35, 40, 45, 50, 75, 100);

@each $size in $sizes {
    .w-#{$size} {
        width: #{$size}px !important;
    }
    .h-#{$size} {
        height: #{$size}px !important;
    }
}

.hidden {
    display: none !important;
}