.anonymous-layout {
    height: 100vh;
    background-image: url("./../../assets/images/gamebase_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    position: relative;

    .ant-layout-content {
        display: flex;
    }
}