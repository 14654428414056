$type: (color,background, border-color);
@each $item in $type { 
    .#{$item} {
        &-blue{
            #{$item}: var(--color-blue) !important; 
        }
        &-blue-2{
            #{$item}: var(--color-blue-2) !important; 
        }
        &-dark-blue{
            #{$item}: var(--color-dark-blue) !important; 
        }
        &-dark-blue-1{
            #{$item}: var(--color-dark-blue-1) !important; 
        }
        &-light-blue{
            #{$item}: var(--color-light-blue) !important; 
        }
        &-yellow{
            #{$item}: var(--color-yellow) !important; 
        }
        &-light-yellow{
            #{$item}: var(--color-light-yellow) !important; 
        }
        &-dark-red{
            #{$item}: var(--color-dark-red) !important; 
        }
        &-light-green{
            #{$item}: var(--color-light-green) !important; 
        }
        &-green{
            #{$item}: var(--color-green) !important; 
        }
        &-black{
            #{$item}: var(--color-black) !important; 
        }
        &-medium-grey{
            #{$item}: var(--color-medium-grey) !important; 
        }
    } 
} 

.card-title-text-color {
    color: var(--card-title-text-color) !important;
}

.blue-linear-gradient-bg {
    background: var(--color-blue);
        background: linear-gradient(0deg, var(--color-blue) 0%, var(--color-blue-2) 100%);
}