.member-financial-date-page {
    .ant-breadcrumb {
        margin-top: 0px !important;
    }

    .club-section-table{
        .ant-table-body {
            height: calc(100vh - 374px);
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }

    .state-section-table{
        .ant-table-body {
            height: calc(100vh - 398px);
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }

    .ant-breadcrumb { 
        .ant-breadcrumb-link {
            color: var(--color-dark-blue);
        }
    }

    .site-form-in-drawer-wrapper {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 100%;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #e9e9e9;
    }

    .ant-table-body .ant-table-expand-icon-col {
        width: 24px !important;
    }

    .ant-table-body .ant-table-selection-col {
        width: 18px !important;
    }

    .ant-btn.btn-icon {
        display: inline-block;
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }

    .position-access-table .ant-table-placeholder .ant-empty {
        margin-block: 12px;
    }

    .position-access-table .ant-table-placeholder .ant-empty-image {
        display: none;
    }

    .ant-modal .ant-modal-content .ant-modal-body {
        padding: 0px 0px;
    }

    .custom-table .ant-table-thead > tr > th {
        color: var(--color-dark-blue);
    }

    .custom-member-table {
        .ant-table-tbody > tr:nth-child(odd) {
            background-color: white;
        }

        .ant-table-tbody > tr:nth-child(even) {
            background-color: var(--color-light-grey-2);
        }
    }

    .ant-tabs-nav {
        margin-bottom: 12px;
        
        .ant-tabs-tab{
            text-transform: uppercase;
        }
    }

    .fields-change-tab {
        .ant-tabs-tab {
            font-size: 12px;
        }
    }

    .ant-btn-icon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    .sub-title {
        font-size: 13px;
    }
    
    .sub-title2 {
        font-size: 13px;
        font-weight: 700;
    }
}

