@import "./../../themes/mixin.scss";

.status-tag {
    border-radius: var(--button-small-radius);
    min-width: 72px;
    color: var(--color-medium-grey);
    background-color: var(--color-transparent-grey);
    font-size: var(--font-size-small-text);
    padding: 5px 11px;
    text-align: center;
    overflow-wrap: normal;

    &--light-red-color,
    &--light-red-color:hover,
    &--light-red-color:focus,
    &--light-red-color:active {
        color: var(--color-dark-red);
        background-color: var(--color-light-red);
    }

    &--light-green-color,
    &--light-green-color:hover,
    &--light-green-color:focus,
    &--light-green-color:active {
        color: var(--color-green);
        background-color: var(--color-light-green);
    }

    &--light-yellow-color,
    &--light-yellow-color:hover,
    &--light-yellow-color:focus,
    &--light-yellow-color:active {
        color: var(--color-yellow);
        background-color: var(--color-light-yellow);
    }

    &--light-blue-color,
    &--light-blue-color:hover,
    &--light-blue-color:focus,
    &--light-blue-color:active {
        color: var(--color-blue);
        background-color: var(--color-light-blue);
    }
}

.btn-container {
    position: relative;

    .ant-badge-status {
        position: absolute;
        top: -11px;
        right: -5px;

        .ant-badge-status-processing {
            background-color: var(--color-yellow);

            &::after {
                border-color: var(--color-yellow);
            }
        }
    }
}

.ant-btn-icon-only {
    &.action-btn {
        background-color: var(--color-light-grey-3);
        @include icon-fill-color(var(--color-medium-grey));
        border-color: var(--color-light-grey-3);
        border-radius: 8px !important;
        height: 34px !important;
        width: 34px !important;
        padding: 0;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus,
        &:active {
            background-color: var(--color-light-grey-3) !important;
            @include icon-fill-color(var(--color-medium-grey));
            border-color: var(--color-light-grey-3) !important;
            opacity: 0.8;
        }

        svg {
            height: 21px !important;
            width: 21px !important;
        }

        .anticon {
            svg {
                height: 18px !important;
                width: 18px !important;
            }
        }

        &--light-red-color,
        &--light-red-color:hover,
        &--light-red-color:focus,
        &--light-red-color:active {
            background-color: var(--color-light-red) !important;
            border-color: var(--color-light-red) !important;
            @include icon-fill-color(var(--color-dark-red));
        }

        &--light-green-color,
        &--light-green-color:hover,
        &--light-green-color:focus,
        &--light-green-color:active {
            background-color: var(--color-light-green) !important;
            border-color: var(--color-light-green) !important;
            @include icon-fill-color(var(--color-green));
        }

        &--light-yellow-color,
        &--light-yellow-color:hover,
        &--light-yellow-color:focus,
        &--light-yellow-color:active {
            background-color: var(--color-light-yellow) !important;
            border-color: var(--color-light-yellow) !important;
            @include icon-fill-color(var(--color-yellow));
        }

        &--light-blue-color,
        &--light-blue-color:hover,
        &--light-blue-color:focus,
        &--light-blue-color:active {
            background-color: var(--color-light-blue) !important;
            border-color: var(--color-light-blue) !important;
            @include icon-fill-color(var(--color-blue));
        }
    }
}

.edit-label {
    font-size: 0.8rem !important;
    font-style: italic;

    &:hover {
        cursor: pointer;
    }
}

.no-message {
    .ant-form-item-explain {
        display: none;
    }
}

.status-truncate {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.text-button-cell:hover .text-button-cell-clear {
    opacity: 1;
}

.text-button-cell-clear {
    inset-inline-start: auto;
    inset-inline-end: 11px;
    z-index: 1;
    display: inline-block;
    width: 14px;
    height: 14px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #ffffff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.2s ease, opacity 0.3s ease;
    text-rendering: auto;
}