.trophy-detail {
    --trophy-detail-container-height: 220px;

    .ant-table-cell {
        min-width: 100px;
    }

    .ant-modal-body {
        max-height: calc(100vh - var(--trophy-detail-container-height));
    }
}