.ant-breadcrumb { 
    .ant-breadcrumb-link {
        color: var(--color-dark-blue);
    }
}

.header-title {
    color: var(--color-dark-red);
}

.member-search {
    .ant-breadcrumb {
        margin-top: 0px !important;
    }
    .section-table{
        .ant-table-body {
            height: calc(100vh - 466px);
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}
