body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
}

@import "./themes/variables";
@import "./themes/spaces";
@import "./themes/colors";
@import "./themes/dividers";
@import "./themes/boxes";
@import "./themes/typography";
@import "./themes/mixin";
@import "./themes/antd-custom.scss";
