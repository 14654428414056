.day-log-summary {
    .section-table {
        .ant-table-body {
            height: calc(100vh - 464px);
            max-height: 100% !important;
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}
