.club-defaults{
    .fishing-location-management {
        .section-table{
            .ant-table-body {
                height: calc(100vh - 438px);
            }
    
            .ant-table-pagination.ant-pagination {
                margin-bottom: 0px;
            }
        }
    }
    
    .point-score-zone-management {
        .section-table{
            .ant-table-body {
                height: calc(100vh - 414px);
            }
    
            .ant-table-pagination.ant-pagination {
                margin-bottom: 0px;
            }
        }
    }

    .special-event-management {
        .section-table{
            .ant-table-body {
                height: calc(100vh - 414px);
            }
    
            .ant-table-pagination.ant-pagination {
                margin-bottom: 0px;
            }
        }
    }
}