.email-members-page {
    .avatar-uploader {

        .ant-upload-select {
            margin-right: 0;
            margin-bottom: 0;
            width: 100% !important;
            height: 110px !important;

            .ant-upload {
                img {
                    height: auto;
                    margin: 0 auto;

                    &.no-image {
                        height: 50%;
                    }
                }
            }
        }
    }

    img.entity-logo {
        max-height: 100%;
        max-width: 100%;
    }

    .remove-logo {
        position: absolute;
        bottom: -8px;
        right: -8px;
    }

    img.entity-logo.no-image {
        height: calc(100% - 40px);
        width: calc(100% - 40px);
    }

    .entity-logo-upload {
        border: 1px solid var(--color-light-grey-1);
        border-radius: var(--input-radius);
        transition: all 0.3s;
        height: 125px;
        padding: 5px;

        button.btn-delete-logo {
            background: rgba(0, 0, 0, 0.15);
            border: 0;
            float: right;
            top: 85px;
            right: -35px;
            border-radius: 5px;
        }

        div.ant-upload {
            float: right;

            button.btn-upload-logo {
                background: rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                border: 0;
            }
        }
    }

    .upload-item {
        height: 133px;
    }

    #formDetail_EntityState {
        font-size: 16px;
    }

    .rdw-editor-toolbar {
        //margin-bottom: 0px !important;
        // border-color: #C1C3C8 !important;
        // border-radius: 6px 6px 0 0 !important;
        // border-bottom: none !important;
        border: none !important;
        margin-top: 2px;
    }

    .rdw-editor-main {
        height: 200px;
        // border-color: #C1C3C8 !important;
        // border-radius: 0 0 6px 6px !important;
        // border-top: none !important;
        border: none !important;
        padding: 5px;
    }

    .rdw-editor-wrapper {
        border: 1px solid #C1C3C8;
        border-radius: 6px;
    }

    .rdw-editor-wrapper:hover {
        border-color: #2986ff;
    }

    .rdw-editor-wrapper:focus-within {
        border-color: #2986ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    }

    .delete-button{
        height: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .to-required{
        label::before{
            display: inline-block;
            margin-inline-end: 4px;
            color: #c71720;
            font-size: 16px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
        }       
    }
}