.boat-category-management {
    .ant-breadcrumb { 
        .ant-breadcrumb-link {
            color: var(--color-dark-blue);
        }
    }
    
    .section-table{
        .ant-table-body {
            height: calc(100vh - 352px);
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}