.ant-breadcrumb { 
    .ant-breadcrumb-link {
        color: var(--color-dark-blue);
    }
}

.email-management {
    .ant-breadcrumb {
        margin-top: 0px !important;
    }
    .section-table{
        .ant-table-body, .ant-table-tbody {
            max-height: calc(100vh - 440px) !important;
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}

.sub-title {
    font-size: 16px;
    color: var(--color-dark-blue);
}