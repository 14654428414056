.add-update-record-modal{
    .ant-modal-body {
        min-height: 450px;
        height: 100vh;
    }
    .avatar-uploader {

        .ant-upload-select {
            margin-right: 0;
            margin-bottom: 0;
            width: 100% !important;    
            height: 110px !important;     

            .ant-upload {               
                img {
                    height: auto;
                    margin: 0 auto;

                    &.no-image {
                        height: 50%;
                    }
                }
            }
        }
    }
    img.record-image {
        max-height: 100%;
        max-width: 100%;
    }

    .remove-image {
        position: absolute;
        bottom: -8px;
        right: -8px;
    }

    img.record-image.no-image {
        height: calc(100% - 40px);
        width: calc(100% - 40px);
    }

    .record-image-upload {
        border: 1px solid var(--color-light-grey-1);
        border-radius: var(--input-radius);
        transition: all 0.3s;
        height: 125px;
        padding: 5px;

        button.btn-delete-logo {
            background: rgba(0, 0, 0, 0.15);
            border: 0;
            float: right;
            top: 85px;
            right: -35px;
            border-radius: 5px;
        }

        div.ant-upload {
            float: right;

            button.btn-upload-logo {
                background: rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                border: 0;
            }
        }
    }

    .upload-item{
        height: 133px;
    }

    #formDetail_EntityState {
        font-size: 16px;
    }
}