body {
    color: var(--color-black);
    font-size: var(--font-size-body);
    font-family: var(--font-family), sans-serif;
    line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: unset;
    margin-bottom: unset;
    color: unset;
    font-weight: normal;
}

p {
    margin-bottom: 0.5rem;
}

::-moz-selection {
    background: var(--color-blue);
}

::selection {
    background: var(--color-blue);
}

html {
    --antd-wave-shadow-color: var(--color-blue);
}

.ant-btn {
    border-radius: var(--button-radius);
    height: 36px;

    &.ant-btn-default:not(.ant-btn-dangerous) {
        color: var(--color-medium-grey);
    }

    &.ant-btn-lg {
        font-size: var(--font-size-body);
        height: var(--button-large-height);
        border-radius: var(--button-radius);
        line-height: 10px;
    }

    &.ant-btn-sm {
        font-size: var(--button-small-font-size);
        height: var(--button-small-height);
    }

    &.btn-outline-blue {
        border-color: var(--color-blue) !important;
        color: var(--color-blue) !important;
    }

    &.btn-outline-dark-blue {
        border-color: var(--color-dark-blue) !important;
        color: var(--color-dark-blue) !important;

        &:disabled {
            color: var(--color-medium-grey) !important;
            border-color: var(--color-light-grey-1) !important;
        }
    }

    &.btn-solid-dark-blue {
        border-color: var(--color-dark-blue) !important;
        background-color: var(--color-dark-blue) !important;
        color: white !important;
    }

    &.ant-btn-link span {
        text-decoration: underline;
    }

    &.btn-icon {
        display: flex;
        align-items: center;

        .ant-btn-icon {
            margin-inline-end: 6px !important;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &.ant-btn-sm {
            .ant-btn-icon {
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        &.ant-btn-lg {
            .ant-btn-icon {
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &.ant-btn-default,
        &.ant-btn-default:disabled {
            .ant-btn-icon {
                @include icon-fill-color(var(--color-medium-grey));
            }

        }

        &.ant-btn-primary,
        &.btn-solid-dark-blue {
            .ant-btn-icon {
                @include icon-fill-color(white);
            }
        }

        &.btn-outline-blue {
            .ant-btn-icon {
                @include icon-fill-color(var(--color-blue));
            }
        }

        &.btn-outline-dark-blue {
            .ant-btn-icon {
                @include icon-fill-color(var(--color-dark-blue));
            }
        }
    }

}

.ant-form {
    .ant-form-item {
        margin-bottom: 8px;

        .ant-form-item-row {
            .ant-form-item-label {
                padding-bottom: 0;
            }
        }
    }
}

.ant-picker {
    display: block;
}

.ant-table {
    .ant-table-tbody>tr:nth-child(odd) {
        background-color: var(--color-light-grey-2);

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right,
        .ant-table-column-sort {
            background-color: var(--color-light-grey-2);
        }
    }

    .ant-table-tbody>tr:nth-child(even) {
        background-color: white;

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right,
        .ant-table-column-sort {
            background-color: white;
        }
    }


    .ant-table-summary {
        tr {
            td {
                color: var(--color-brown);
                font-weight: var(--font-weight-bold);
                padding: 10px;

                span {
                    color: var(--color-brown);
                }
            }
        }
    }
}

.ant-menu {
    .ant-menu-submenu-title {
        padding-inline-end: 15px;
        padding-left: 15px;
    }

    svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--color-dark-blue);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    font-size: var(--font-size-body);
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-color: white !important;
}

.ant-card {
    border: none;
    border-radius: 10px;
    box-shadow: var(--card-box-shadow);

    .ant-card-body {
        padding: 15px;
        border-radius: 0;
    }
}

.ant-input-search :not(:hover) {
    .ant-input {
        border-right-width: 0 !important;
    }

    .ant-input:focus {
        border-right-width: 1px !important;
    }

    .ant-input-group-addon {
        .ant-btn {
            border-left-width: 0 !important;
        }
    }
}

.ant-input-number-group-wrapper {
    min-width: 100%;
}

.ant-input-number {
    min-width: 100%;
}

.ant-menu {
    .ant-menu-item {
        &.ant-menu-item-selected {
            color: white;
            background: var(--color-blue);
            background: linear-gradient(0deg, var(--color-blue) 0%, var(--color-blue-2) 100%);
            @include icon-fill-color(white);
        }
    }

    .ant-menu-submenu-selected {
        > div.ant-menu-submenu-title {
            color: var(--color-blue);
            @include icon-fill-color(var(--color-blue));
        }
    }

    .ant-menu-submenu {
        &.level0 {
            .ant-menu-item {
                margin-left: 48px;
                width: calc(100% - 56px);
                padding-left: 16px !important;
            }
        }
    }
}
