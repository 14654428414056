
.date-approvals-section {
    .date-approvals-section-table {
        .ant-table-body {
            height: calc(100vh - 380px);
        }

        .ant-table-pagination.ant-pagination {
            margin-bottom: 0px;
        }
    }
}