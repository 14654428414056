
// $color-primary: #009344;
// $color-dark-green: #006738;
// $color-blue: #216A98;
// $color-light-green: #E8F4ED;
:root {
    // --color-primary: #{$color-primary};
    --color-blue: #0067FF;
    --color-blue-2: #009EFF;
    --color-light-blue: #ECF1FF;
    --color-dark-blue: #264A80;
    --color-dark-blue-1: #172C4D;
    --color-green: #009355;
    --color-light-green: #E8F4ED;
    
    --color-yellow: #E49013;
    --color-light-yellow: #FEF6EC;
    
    
    --color-dark-red: #C71720;
    --color-light-red: #FFE7E5;

    --color-black: #111111;
    --color-brown: #6c472e;

    --color-medium-grey: #646A7C;
    --color-light-grey-1: #C1C3C8;
    --color-light-grey-2: #F7F7F7;
    --color-light-grey-3: #E6E6E6;
    --color-transparent-grey: #00000014;

    --color-input-border: #C1C3C8;
    --color-background-light-blue: #FAFBFF;
    --color-disabled-input-background: #e4e4e4;

    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;

    --font-size-heading-1: 35px;
    --font-size-heading-2: 30px;
    --font-size-heading-3: 25px;
    --font-size-heading-4: 22px;
    --font-size-heading-5: 18px;
    --font-size-body: 16px;
    --font-size-small-text: 14px;
    --font-size-tab: 12px;

    --line-height-heading-1: 48px;
    --line-height-heading-2: 34px;
    --line-height-heading-3: 30px;
    --line-height-heading-4: 22px;
    --line-height-heading-5: 25px;
    --line-height-body: 24px;

    --button-large-font-size: 16px;
    --button-medium-font-size: 15px;
    --button-small-font-size: 14px;
    --button-radius: 10px;
    --button-small-radius: 5px;

    --button-large-height: 40px;
    --button-medium-height: 32px;
    --button-small-height: 26px;

    --card-radius: 10px;
    --menu-item-radius: 12px;
    --menu-item-heigh: 40px;
    --opacity: 0.75;

    --card-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
    --color-gradient-blue: linear-gradient(0deg, var(--color-blue-2) 0%, var(--color-blue) 100%);

    --card-title-text-color: #{--color-dark-blue};

    --selected-item-dropdown-background-color: #{--color-light-blue};

    --badge-background-color: #ff4d4f;
    --badge-text-color: white;
    --table-header-highlight-text-color: #b96712;

    --root-height: unset;
    --color-input-focus-border: #0067ff;
    --input-box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}