.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #e9e9e9;
}

.member-view-club-contacts {
    .ant-table-body .ant-table-expand-icon-col {
        width: 24px !important;
    }
    
    .ant-table-body .ant-table-selection-col {
        width: 18px !important;
    }
    
    .ant-btn.btn-icon {
        display: inline-block;
    }
    
    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }
    
    .position-access-table .ant-table-placeholder .ant-empty {
        margin-block: 12px;
    }
    
    .position-access-table .ant-table-placeholder .ant-empty-image {
        display: none;
    }
    
    .ant-modal .ant-modal-content .ant-modal-body {
        padding: 0px 0px;
    }
    
    .ant-card {
        border-radius: 20px;
    }

    .member-club-details {
        &__row {
          margin-bottom: 24px;
        }
      
        &__card {
          height: 100%;
        }
      
        &__label {
          font-weight: bold;
          margin-bottom: 5px;
        }
      
        &__value {
          margin-bottom: 15px;
        }
      
        &__hyperlink {
          color: #1890ff;
          cursor: pointer;
          text-decoration: underline;
        }
      
        &__image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          img {
            max-width: 100%;
            max-height: 200px;
            object-fit: contain;
          }
        }
      }
}


