.login-page {
    background-color: white;
    padding: 20px 25px 25px;
    border-radius: 30px;
    box-shadow: var(--card-box-shadow);
    width: 420px;
    margin: auto;
    text-align: center;

    .ant-btn.ant-btn-lg {
        height: 46px;
    }

    .ant-input-prefix{
        svg{
            width: 24px;
            height: 24px;
            margin-right: 5px;
            path{
                stroke: none;   
                fill: var(--color-light-grey-1);   
            }   
        }           
    }
}