@import "themes/typography.scss";

:root {
  --app-header-height: 70px;
  --fix-items-container-height: 300px;
  --modal-fix-items-container-height: 220px;
  --button-group-width: 0;
  --vertical-modal-fix-items-container-height: 95px;
}

/*------------End Toast style------------*/
// .title-field {
//   @extend .semi-bold-text;
//   color: var(--color-black);
// }

// .text-field {
//   @extend .semi-bold-text;
// }

h1 {
  @extend .heading-1;
}

h2 {
  @extend .heading-2;
}

h3 {
  @extend .heading-3;
}

h4 {
  @extend .heading-4;
}

h5 {
  @extend .heading-5;
}

.ant-table {
  line-height: 23px;

  .ant-table-thead>tr>th {
    padding: 12px 10px;
    white-space: normal !important;
    word-break: break-word !important;

    .ant-table-column-sorters {
      padding: 0;

      .ant-table-column-sorter {
        margin-top: -4px;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 10.5px 10px;
      }

      &.editable-row {
        td {
          padding: 6px 10px;
        }
      }
    }
  }



  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down,
  .ant-table-filter-trigger {
    @include breakpoint(tab) {
      font-size: 9px;
    }

    &.active {
      color: var(--color-blue);
    }
  }

  .ant-table-filter-trigger {
    @include breakpoint(tab) {
      font-size: 10px;
    }
  }

  .ant-table-body {
    overflow: auto auto !important;
    max-height: calc(100vh - var(--app-header-height) - var(--fix-items-container-height)) !important;
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: var(--card-radius);

    .ant-modal-title {
      font-size: var(--font-size-heading-6);
      color: var(--color-medium-grey);
    }

    .ant-modal-header {
      border-radius: var(--card-radius) var(--card-radius) 0 0;
      border-bottom: 0;
    }

    .ant-modal-body {
      padding-top: 10px;
      max-height: calc(100vh - var(--modal-fix-items-container-height));
      overflow-y: auto;

      .ant-list-items {
        margin: 10px 0;
        max-height: calc(100vh - 350px);
        overflow-y: auto;

        .ant-list-item {
          border-bottom: none;
        }
      }
    }

    .ant-modal-footer {
      border-top: none;
    }
  }
}

@include breakpoint(pc) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.wrap-text-dropdown {
  .ant-select-item-option-content {
    white-space: break-spaces;
    word-break: break-word;
  }
}

.ant-input-number {
  &.hide-stepper {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}

.required:after {
  content: " *";
  color: var(--color-dark-red);
}

.vertical-modal {
  height: 100vh;

  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: calc(100% - var(--vertical-modal-fix-items-container-height));
      max-height: calc(100% - var(--vertical-modal-fix-items-container-height));

    }

    .ant-modal-footer {
      padding: 15px 0;
    }

  }
}

.vertical-modal {
  margin: 0 0 0 auto;
  height: 100vh;
  top: 0;
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    border-radius: 0;


  }

  &.no-footer {
    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 80px);
      }
    }
  }

  &.modal-open {
    animation: open 0.3s;
  }

  @keyframes open {
    from {
      left: 400px;
    }

    to {
      left: 0;
    }
  }
}

.pointer {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.upload-logo {
  .avatar-uploader {

    .ant-upload-select {
      margin-right: 0;
      margin-bottom: 0;
      width: 100% !important;
      height: 110px !important;

      .ant-upload {
        img {
          height: auto;
          margin: 0 auto;

          &.no-image {
            height: 50%;
          }
        }
      }
    }
  }

  img.logo {
    max-height: 100%;
    max-width: 100%;
  }

  .remove-logo {
    position: absolute;
    bottom: -8px;
    right: -8px;

    button {
      width: 38px;
      height: 38px;
    }
  }

  img.entity-logo.no-image {
    height: calc(100% - 40px);
    width: calc(100% - 40px);
  }

  .upload-item {
    height: 133px;
  }
}

