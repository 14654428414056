@import './mixin.scss';

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.bold-text {
    font-weight: var(--font-weight-bold);
}

.semi-bold-text {
    font-weight: var(--font-weight-semi-bold);
}

.uppercase-text {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}


.heading-1 {
    @include text-style(var(--font-size-heading-1), var(--line-height-heading-1), false);
}

.heading-2 {
    @include text-style(var(--font-size-heading-2), var(--line-height-heading-2), false);
}

.heading-3 {
    @include text-style(var(--font-size-heading-3), var(--line-height-heading-3), false);
}

.heading-4 {
    @include text-style(var(--font-size-heading-4), var(--line-height-heading-4), false);
}

.heading-5 {
    @include text-style(var(--font-size-heading-5), var(--line-height-heading-5), false);
}

.text-body {
    @include text-style(var(--font-size-body), var(--line-height-body), false);
}

.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}
.truncate-flex-item {
    flex: 1;
    @extend .truncate;
}

.included-spaces {
    white-space: pre;
}
