@import './../../themes/mixin.scss';

.main-layout {
    &.ant-layout {
        .ant-layout-header {
            position: sticky;
            top: 0;
            z-index: 1;
            width: '100%';
            display: 'flex';
            align-items: 'center';
            padding: 0;
        }

        .ant-layout-has-sider {
            .ant-layout {
                padding: 0 20px 15px;
                height: calc(100vh - 76px);
            }

            .ant-layout-sider {
                max-height: calc(100vh - 76px);
                overflow-y: auto;
            }
        }

        .ant-breadcrumb {
            margin: 10px 0;
        }

        .ant-layout-content {
            padding: 10px 15px;
            margin: 0;
            background: white;
            border-radius: 20px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .left-container {
            .ant-btn-icon {
                transition: all 0.5s;
                transform: rotate(0);
            }

            .collapsed {
                .ant-btn-icon {
                    transform: rotate(180deg);
                }
            }

            .ant-avatar {
                height: auto;
                width: auto;
                border-radius: 0;
            }

            .ant-btn-icon-only {
                background-color: var(--color-light-blue);
                height: 46px;
                width: 36px;
                border-radius: 30px 0 0 30px;
                line-height: 36px;

                .ant-btn-icon {
                    @include icon-fill-color(var(--color-blue));

                    svg {
                        height: 16px;
                        width: 10px;
                    }
                }
            }

        }

        .right-container {
            display: flex;
            flex: 1;
            padding: 0 20px;

            &--left-component {
                .ant-avatar {
                    height: 50px;
                    width: auto;
                    border-radius: 0;
                }

                .ant-select {
                    .ant-select-selector {
                        background-color: var(--color-light-blue);
                        border-radius: 30px;
                        border-color: var(--color-light-blue);
                    }

                    .ant-select-arrow {
                        color: var(--color-dark-blue);
                    }
                }
            }

            &--right-component {
                .ant-space-item {
                    .ant-btn {
                        height: 38px !important;
                        width: 38px !important;
                        padding: 0;
                        line-height: 38px;
                        font-weight: var(--font-weight-semi-bold);
                        text-transform: capitalize;
                    }
                }
            }
        }

        // .ant-menu-root {
        //     .ant-menu-item {
        //         color: var(--color-dark-blue);
        //         padding: 0px 0px 0px 10px !important;
        //         height: var(--menu-item-heigh);
        //         line-height: var(--menu-item-heigh);
        //         svg {
        //             height: 22px;
        //             width: 22px;
        //         }

        //         &.ant-menu-item-selected {
        //             color: var(--color-blue);
        //         }
        //         .ant-menu-title-content {
        //             margin-left: 10px;
        //         }
        //     }

        //     .ant-menu-submenu {
        //         color: var(--color-dark-blue);

        //         &.ant-menu-submenu-selected {
        //             color: var(--color-blue);
        //         }

        //         .ant-menu-submenu-title {
        //             padding: 0px 0px 0px 10px !important;
        //             height: var(--menu-item-heigh);
        //             line-height: var(--menu-item-heigh);

        //             &.ant-menu-submenu-selected {
        //                 color: var(--color-blue);
        //             }

        //             .ant-menu-title-content {
        //                 margin-left: 10px;
        //             }

        //             svg {
        //                 height: 22px;
        //                 width: 22px;
        //             }

        //         }

        //         .ant-menu-sub {
        //             background-color: white;

        //             .ant-menu-item {
        //                 color: var(--color-dark-blue);
        //                 width: calc(100% - 36px);
        //                 float: right;
        //                 height: var(--menu-item-heigh);
        //                 line-height: var(--menu-item-heigh);
        //                 text-align: left;
        //                 padding: 0 15px !important;
        //             }

        //             .ant-menu-item-selected {
        //                 color: white;
        //                 background: var(--color-gradient-blue);
        //                 border-radius: var(--menu-item-radius);
        //             }

        //         }

        //         .ant-menu-submenu-arrow {
        //             inset-inline-end: 10px;
        //         }
        //     }

        // }
    }
}