.forget-password-page {
    background-color: white;
    padding: 20px 25px 25px;
    border-radius: 30px;
    box-shadow: var(--card-box-shadow);
    width: 420px;
    margin: auto;
    text-align: center;

    .ant-btn.ant-btn-lg {
        height: 46px;
        max-width: 385px;
    }

    .ant-input-prefix {
        svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;

            path {
                stroke: none;
                fill: var(--color-light-grey-1);
            }
        }
    }

    .button-bar {
        display: flex;
        justify-content: center;
        background: none;
        border-radius: 50px;
        border: 1.5px solid #009EFF;
        width: 310px;
        margin: 15px auto 15px auto;     

        button:first-child {
            border-radius: 50 0 0 50;
            border: none;
            cursor: pointer;
        }

        button:nth-child(2) {
            border-left: 1px solid #009EFF;
            border-right: 1px solid #009EFF;
            border: none;
            cursor: pointer;
        }

        button:nth-child(3) {
            border-radius: 0 50px 50px 0;
            border: none;
            cursor: pointer;
        }

        button {
            background: none;
            border-radius: 0;
            padding: 11px 9px;
            margin: 0;
            border-right: none;
            color: #009EFF;
            transition: background 1s;
            white-space: nowrap;
            width: 100%;
        }

        .button-positive {
            background: #009EFF;
            color: white;
        }

        .button-extended {
            width: 50%;
            border-right: none !important;
            border-radius: 0 50px 50px 0 !important;

            &:first-child {
                border-radius: 50px 0 0 50px !important;
            }
        }
    }
}
.forget-password-result-page {
    background-color: white;
    padding: 20px 25px 25px;
    border-radius: 30px;
    box-shadow: var(--card-box-shadow);
    width: 988px;
    margin: auto;
    text-align: center;

    .ant-btn.ant-btn-lg {
        height: 46px;
        max-width: 385px;
    }

    .ant-input-prefix {
        svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;

            path {
                stroke: none;
                fill: var(--color-light-grey-1);
            }
        }
    }
}