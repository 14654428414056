//============================================
// breakpoint
//============================================
@mixin breakpoint($point) {
	@if $point==pc {
		@media only screen and (min-width: 1024px) {
			@content ;
		}
	}

	@else if $point==tab {
		@media screen and (max-width: 1023px) and (min-width: 768px) {
			@content ;
		}
	}

	@else if $point==sp {
		@media only screen and (max-width: 767px) {
			@content ;
		}
	}
}

@mixin mobile-landscape($max-width) {
	@media (max-width: $max-width) and (orientation:landscape) {
		@content;
	}
}

//============================================
// button
//============================================

@mixin btn-primary-color($color) {
	color: white;
	background-color: $color;
	border: 1px solid $color;
}

@mixin outline-btn($color) {
	color: $color;
	background-color: white;
	border: 1px solid $color;
}


@mixin btn-default-color() {
	color: var(--color-medium-grey);
	background-color: white;
	border: 1px solid var(--color-light-grey-1);
}

@mixin btn-disabled-color() {
	color: var(--color-medium-grey);
	background-color: var(--color-transparent-grey);
	border: 1px solid var(--color-transparent-grey);
}

@mixin icon-fill-color($color) {
	svg path {
		fill: $color;
	}
}

@mixin icon-stroke-color($color, $stroke-width: 1 ) {
	svg path {
		stroke: $color;
		stroke: $color;
		stroke-width: $stroke-width;
	}
}


@mixin text-style($font-size, $line-height, $important) {

	@if $important {
		font-size: $font-size !important;
		line-height: $line-height !important;
	}

	@else {
		font-size: $font-size;
		line-height: $line-height;
	}
}