.ant-checkbox-wrapper {
    color: #111111;
}

.ant-checkbox-wrapper-header {
    color: #111111;
}

.tag-card-form-view {
    border-radius: 0px;
    border: none;

    .tag-card-form-header-view {
        padding-bottom: 0px !important;
        padding-top: 8px !important;
        height: 42px !important;

        .ant-descriptions-item-label, .ant-descriptions-item-content {
            color: var(--color-dark-blue);
            font-size: 16px;
            font-weight: 700;
        }

        .ant-delete-tag {
            color: var(--color-dark-red);
            font-style: italic;
        }
    }
    
    
    .tag-card-form-body-view {
        padding: 12px !important;
        
        .tag-card-form-view-table {
            padding-top: 24px !important;
            padding-right: 8px !important;

            .ant-table-body {
                overflow-y: hidden !important;
                max-height: 100% !important;
            }

            .ant-table .ant-table-tbody tr.editable-row td {
                padding: 6px 4px !important;
            }
        }
        
        .custom-checkbox .ant-form-item-label {
            text-align: center;
        }
    }
}

