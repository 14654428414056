.ant-checkbox-wrapper {
    color: #111111;
}

.ant-checkbox-wrapper-header {
    color: #111111;
}

.club-approvals {
    border: 2px solid #f0f0f0 !important;
}


.club-approvals-children-left {
    border-left: 2px solid #f0f0f0;
    border-right: 2px solid #f0f0f0;
}

.club-approvals-children-right {
    border-right: 2px solid #f0f0f0;
}

.tag-card-table-view {
    .custom-section-table{
        .ant-table-body {
            overflow-y: hidden !important;
            max-height: 100% !important;
        }
    }
}