.custom-mask-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #264A80;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    font-family: Nunito Sans;
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 6px 11px;
    font-size: 16px;
    line-height: 1.5714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    transition: all 0.2s;
    

    &:focus-visible, &:active {
        border-color: #0067ff !important;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: 0;
    }

    &:hover {
        border-color: #0067ff !important;
        outline: 0;
    }
}