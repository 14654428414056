.ant-breadcrumb { 
    .ant-breadcrumb-link {
        color: var(--color-dark-blue);
    }
}

.ant-table-body .ant-table-expand-icon-col {
    width: 24px !important;
}

.ant-table-body .ant-table-selection-col {
    width: 18px !important;
}

.ant-btn.btn-icon {
    display: inline-block;
}

.ant-form-item-extra {
    color: #0067ff !important;
}