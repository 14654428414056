.state-defaults {
    .custom-state-logo {
        top: 48px;
        min-height: 200px;
        width: auto;
        border-radius: 0;
    }

    .point-score-zone-management {
        .section-table{
            .ant-table-body {
                height: calc(100vh - 414px);
            }
    
            .ant-table-pagination.ant-pagination {
                margin-bottom: 0px;
            }
        }
    }

    .special-event-management {
        .section-table{
            .ant-table-body {
                height: calc(100vh - 414px);
            }
    
            .ant-table-pagination.ant-pagination {
                margin-bottom: 0px;
            }
        }
    }
}
