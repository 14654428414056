.state-approvals-status {
    .ant-table {
        .ant-table-header {


            .ant-table-cell {

                &.approval-status,
                &.disqualification,
                &.item {
                    border-color: #859ca9 !important;
                    border-style: solid;
                }

                &.approval-status {
                    border-width: 2px !important;
                }

                &.disqualification {
                    border-width: 2px 2px 2px 0px !important;
                }


                &.item {
                    border-width: 0px 2px 2px 0px !important;
                    &:first-child {
                        border-left-width: 2px !important;
                    }
                    &:last-child {
                        border-right-width: 2px !important;
                    }
                }

            }
        }

        .ant-table-row {
            .ant-table-cell {
                &.item {
                    &:nth-child(2){
                        border-left-color: #859ca9 !important;
                        border-left-style: solid;
                        border-left-width: 2px !important;
                    }

                    &:nth-child(5) {
                        border-right-color: #859ca9 !important;
                        border-right-style: solid;
                        border-right-width: 2px !important;
                    }

                    &:last-child {
                        border-color: #859ca9 !important;
                        border-style: solid;
                        border-width: 0px 2px 0px 0px !important;
                    }
                }
            }
        }

        .ant-table-summary {
            .ant-table-cell.text-center {
                border-color: #859ca9 !important;
                border-style: solid;
                border-width: 2px 2px 2px 0px !important;

                &:nth-child(2) {
                    border-width: 2px !important;
                }
            }
        }
    }
}